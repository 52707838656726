import constants from "@/helpers/constants.js"

// The default fallback value that should be used if there's no such row present for the user in database the allowed services
const defaultServiceValueMap = {
  // Services
  [constants.model.user.allowedServices.influencerDiscovery]: true,
  [constants.model.user.allowedServices.influencerInsight]: true,
  [constants.model.user.allowedServices.paidCollaborations]: true,
  [constants.model.user.allowedServices.audienceOverlap]: true,
  [constants.model.user.allowedServices.influencerTieBreaker]: true,
  [constants.model.user.allowedServices.paidComparison]: true,
  [constants.model.user.allowedServices.googleTrends]: false,
  [constants.model.user.allowedServices.profileOverview]: true,
  [constants.model.user.allowedServices.socialSentiments]: false,
  [constants.model.user.allowedServices.competitorCheck]: false,
  [constants.model.user.allowedServices.campaignPlanning]: false,

  // CRM Features
  [constants.model.user.allowedServices.mentionTracking]: false,
  [constants.model.user.allowedServices.campaignTracking]: true,
  [constants.model.user.allowedServices.influencerGroups]: true,
  [constants.model.user.allowedServices.influencerOutreach]: false,
  [constants.model.user.allowedServices.mentionTrackingGroup]: true,

  // Actions
  [constants.model.user.allowedServices.actionContentDiscovery]: false,
  [constants.model.user.allowedServices.actionMassDiscoveryDownload]: false,
  [constants.model.user.allowedServices.actionBulkExportInfluencerInsightsPDF]: false,
  [constants.model.user.allowedServices.actionBulkExportInfluencerInsightsXLSX]: false,

  // Filters
  [constants.model.user.allowedServices.filterDiscoveryEthnicity]: false,

  // Platforms
  [constants.model.user.allowedServices.platformDiscoveryTwitter]: false,
  [constants.model.user.allowedServices.platformDiscoverySnapchat]: false,
  [constants.model.user.allowedServices.platformDiscoveryLinkedIn]: true
}

// The default fallback values that should be used if there's no such row present for the user in database for the metas
const defaultMetaValueMap = {
  [constants.model.user.metas.discoveryExportsLimit]: 100,

  [constants.model.user.metas.popup.info.influencerDiscovery]: "should-show",
  [constants.model.user.metas.popup.info.influencerInsight]: "should-show",
  [constants.model.user.metas.popup.info.campaignTracking.publicReports]: "should-show",

  [constants.model.user.metas.email.influencerDiscoveryExportReady]: "should-send",
  [constants.model.user.metas.email.influencerInsightExportReady]: "should-send",
  [constants.model.user.metas.email.paidCollaborationExportReady]: "should-send",
  [constants.model.user.metas.email.audienceOverlapReady]: "should-send",
  [constants.model.user.metas.email.socialSentimentReady]: "should-send",
  [constants.model.user.metas.email.competitorCheckReady]: "should-send",
  [constants.model.user.metas.email.contentDiscoveryReady]: "should-send",
  [constants.model.user.metas.email.influencerGroupInfluencersImported]: "should-send",
  [constants.model.user.metas.email.campaignTrackingInfluencersImported]: "should-send",
  [constants.model.user.metas.email.mentionTrackingReady]: "should-send",
  [constants.model.user.metas.email.reportShared]: "should-send",
}

export default {
  /**
   * Whether a user is logged in or not
   *
   * @param {Object} state
   * @returns {Boolean}
   */
  isAuthenticated(state) {
    return state.data === null ? false : true
  },

  /**
   * If logged in, send the user info object
   *
   * @param {Object} state
   * @returns {Object}
   */
  profile(state) {
    return state.data
  },

  /**
   * If available, show the profile logo for this user
   *
   * @param {Object} state
   * @returns {String}
   */
  profileLogo(state) {
    return state.data && state.data.logo ? `${process.env.VUE_APP_STORAGE_BASE}/${state.data.logo}` : null
  },

  /**
   * If available, show the profile watermark for this user
   *
   * @param {Object} state
   * @returns {String}
   */
  profileWatermark(state) {
    return state.data && state.data.watermark ? `${process.env.VUE_APP_STORAGE_BASE}/${state.data.watermark}` : null
  },

  /**
   * If available, show the profile role for this user
   *
   * @param {Object} state
   * @returns {String}
   */
  profileRole(state) {
    return state.data ? state.data.role : "end-user"
  },

  /**
   * Get whether the authenticated user is an admin or not
   *
   * @param {Object} state
   * @returns {Boolean}
   */
  isAdmin(state) {
    return state.data && state.data.role === window.constants.model.user.roles.admin
  },

  /**
   * Get whether the authenticated user is a whitelisted user or not
   *
   * @param {Object} state
   * @returns {Boolean}
   */
  isWhitelisted(state) {
    return state.data && state.data.is_whitelisted
  },

  /**
   * Get whether the authenticated user is a team member or not
   *
   * @param {Object} state
   * @returns {Boolean}
   */
  isPartOfTeam(state) {
    return state.data && (state.data.is_whitelisted || state.data.parent_id)
  },

  /**
   * Get the currently authenticated user's credit balance value
   *
   * @returns {Number}
   */
  availableCredits(state) {
    return state.data && state.data.balance ? state.data.balance / window.constants.conversionRate : 0
  },

  /**
   * Get the requested module's credit value amount
   *
   * @param {Object} state
   * @param {String} moduleName
   * @returns {Number}
   */
  availableModuleUsage: (state) => (moduleName) => {
    return state.data ? state.data.dividedBalance[moduleName] / window.constants.conversionRate : 0
  },

  /**
   * Whether the authenticated user should be able to view and use a particular module
   *
   * @param {Object} state
   * @returns {Boolean}
   */
  isServiceAllowed: (state) => (serviceName) => {
    // Get all the values from API response
    const allowedServices = state.data ? state.data.allowedServices : []

    // Find if it exists
    const searchItem = allowedServices.find((search) => search.name === serviceName)

    // Fallback to the default map
    return searchItem
      ? searchItem.status
      : typeof defaultServiceValueMap[serviceName] === "boolean"
      ? defaultServiceValueMap[serviceName]
      : true
  },

  /**
   * Get the authenticated user's meta key values and fallback to default
   *
   * @param {Object} state
   * @param {String} metaKey
   * @param {Any} defaultValue
   * @returns {String|Null}
   */
  getMetaValue: (state) => (metaKey, defaultValue = null) => {
    // Get all the values from API response
    const metas = state.data ? state.data.metas : []

    // Find if it exists
    const searchItem = metas.find((search) => search.key === metaKey)

    // Fallback to the default map
    return searchItem ? searchItem.value : typeof defaultMetaValueMap[metaKey] !== "undefined" ? defaultMetaValueMap[metaKey] : defaultValue
  }
}
