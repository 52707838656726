// Import necessary packages
import Vue from 'vue'
import Vuex from 'vuex'

// Register Vuex store with Vue object
Vue.use(Vuex)

// Import the modules
import app from './app/index.js'
import auth from './auth/index.js'
import team from './team/index.js'
import admin from './admin/index.js'
import config from './config/index.js'
import toasts from './toasts/index.js'
import reports from './reports/index.js'
import loaders from './loaders/index.js'
import progress from './progress/index.js'
import googleTrends from './googleTrends/index.js'
import savedSearches from './savedSearches/index.js'
import notifications from './notifications/index.js'
import profileOverview from './profileOverview/index.js'
import audienceOverlap from './audienceOverlap/index.js'
import competitorCheck from './competitorCheck/index.js'
import contentDiscovery from './contentDiscovery/index.js'
import socialSentiments from './socialSentiments/index.js'
import campaignPlanning from './campaignPlanning/index.js'
import influencerInsight from './influencerInsight/index.js'
import paidCollaborations from './paidCollaborations/index.js'
import influencerDiscovery from './influencerDiscovery/index.js'

// CRM related store modules
import mentionTracking from "./crm/mentionTracking/index.js"
import campaignTracking from "./crm/campaignTracking/index.js"
import influencerGroups from "./crm/influencerGroups/index.js"
import influencerOutreach from "./crm/influencerOutreach/index.js"
import mentionTrackingGroup from "./crm/mentionTrackingGroup/index.js"

// Export a new instance of the Vuex store object
export default new Vuex.Store({
  // Load individual component's data stores from individually exported files
  modules: {
    app,
    auth,
    team,
    admin,
    config,
    toasts,
    reports,
    loaders,
    progress,
    googleTrends,
    savedSearches,
    notifications,
    profileOverview,
    audienceOverlap,
    competitorCheck,
    contentDiscovery,
    socialSentiments,
    campaignPlanning,
    influencerInsight,
    paidCollaborations,
    influencerDiscovery,

    mentionTracking,
    campaignTracking,
    influencerGroups,
    influencerOutreach,
    mentionTrackingGroup,
  }
})
