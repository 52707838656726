// Define a global cache object
const globalCache = new Map()
const timeoutHandles = new Map()

// Add it to global scope
globalThis.__MEMOIZE_GLOBAL_CACHE__ = globalCache

/**
 * Define the memoize function
 *
 * @param {string} keyPrefix The key to use for the cache
 * @param {Function} fn The function to memoize
 * @param {Object} options The options object
 * @returns {Function} The memoized function
 */
export default function memoize(keyPrefix, fn, options = {}) {
  // Get the timeout from the options
  const timeout = options.timeout || null

  // Return the memoized function
  return function (...args) {
    // Convert the arguments to a key
    const key = `${keyPrefix}:${JSON.stringify(args)}`

    // If we have a timeout
    if (timeout) {
      // Clear any existing timeout for this key
      if (timeoutHandles.has(key)) {
        clearTimeout(timeoutHandles.get(key))
      }

      // Set a new timeout
      const handle = setTimeout(() => {
        globalCache.delete(key)
        timeoutHandles.delete(key)
      }, timeout * 1000)

      // Store the timeout handle
      timeoutHandles.set(key, handle)
    }

    // Check if the key exists in the cache
    if (globalCache.has(key)) {
      // Return the value from the cache
      return globalCache.get(key)
    }

    // Otherwise
    const result= fn(...args)

    // Handle async functions
    if (result instanceof Promise) {
      // Cache the resolved value
      const promise = result.then((res) => {
        globalCache.set(key, res)
        return res
      })

      // Cache the promise to avoid duplicate calls
      globalCache.set(key, promise)
      return promise
    }

    // Handle synchronous functions
    globalCache.set(key, result)
    return result
  }
}
