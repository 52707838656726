
import constants from '@/helpers/constants.js'

export default [
  {
    path: '/profile-overview',
    name: 'ProfileOverviewHistory',
    component: () => import(/* webpackChunkName: "profile-overview-history" */ '@/views/profileOverview/History.vue'),
    meta: {
      title: "Custom ER Calculator",
      appBarTitle: "Custom ER Calculator",
      template: "dashboard",
      serviceRequired: constants.model.user.allowedServices.profileOverview
    }
  },
  {
    path: '/profile-overview/report/:uuid',
    name: 'ProfileOverviewReport',
    component: () => import(/* webpackChunkName: "profile-overview-report" */ '@/views/profileOverview/Report.vue'),
    meta: {
      title: "Custom ER Calculator",
      appBarTitle: "Custom ER Calculator",
      template: "dashboard",
      serviceRequired: constants.model.user.allowedServices.profileOverview
    }
  },
]
