// Import helper objects
import constants from '@/helpers/constants.js'

// Export the route items
export default [
  {
    path: '/competition-analysis',
    name: 'MentionTrackingGroupIndex',
    component: () => import(/* webpackChunkName: "mention-tracking-group-index" */ '@/views/crm/mentionTrackingGroup/Index.vue'),
    meta: {
      title: "Competition Analysis",
      appBarTitle: "Competition Analysis",
      template: "dashboard",
      serviceRequired: constants.model.user.allowedServices.mentionTrackingGroup
    }
  },

  {
    path: '/competition-analysis/create',
    name: 'MentionTrackingGroupCreate',
    component: () => import(/* webpackChunkName: "mention-tracking-group-create" */ '@/views/crm/mentionTrackingGroup/Create.vue'),
    meta: {
      title: "New Competition Analysis",
      appBarTitle: "New Competition Analysis",
      template: "dashboard",
      serviceRequired: constants.model.user.allowedServices.mentionTrackingGroup
    }
  },

  {
    path: '/competition-analysis/view/:uuid',
    name: 'MentionTrackingGroupView',
    redirect: { name: "MentionTrackingGroupReport" },
    component: () => import(/* webpackChunkName: "mention-tracking-group-view" */ '@/views/crm/mentionTrackingGroup/View.vue'),
    children: [
      {
        path: "report",
        name: "MentionTrackingGroupReport",
        component: () => import(/* webpackChunkName: "mention-tracking-group-report" */ '@/views/crm/mentionTrackingGroup/Report.vue'),
        meta: {
          title: "Competition Analysis",
          appBarTitle: "Competition Analysis",
          template: "dashboard",
          serviceRequired: constants.model.user.allowedServices.mentionTrackingGroup,
          isGuestAllowed: true,
          switchTemplateToMinimalIfUnauthenticated: true
        },
      },
    ]
  },
]
